import React from 'react'
import EnquiryForm from './components/EnquiryForm'

export default function EnquiryLandingPage() {
  return (
    <>
    <div class="enquiry-form fadeInAnimation">
    <h1 class="enquiry-form--header">How can we help?</h1>
    <EnquiryForm />
    </div>
    </>
  )
}
